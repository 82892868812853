import React, { useState } from 'react';
import { Box, Badge, Flex, SimpleGrid, FormControl, FormLabel, Text, Input, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function ContactSetup({ user, setUser, setStep }) {
    const handleNextClick = () => {
        setStep(3)
    }

    const [socialMedia, setSocialMedia] = useState(user.socialMedia);
    const [socialUrl, setSocialUrl] = useState('');
    const [socialHandle, setSocialHandle] = useState('');

    const handleAddMore = () => {
        setSocialMedia([...socialMedia, { socialUrl, socialHandle }]);
        setUser(prevUser => ({
            ...prevUser,
            socialMedia: [...socialMedia, { socialUrl, socialHandle }]
        }));
        setSocialUrl(''); // clear the input field
        setSocialHandle(''); // clear the input field
    };

    const handleRemove = (index) => {
        const updatedSocialMedia = socialMedia.filter((_, i) => i !== index);
        setSocialMedia(updatedSocialMedia);
        setUser(prevUser => ({
            ...prevUser,
            socialMedia: socialMedia
        }));
    };


    return (
        <>
            <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="50">
                <SimpleGrid
                    width="100%"
                    columns={{ base: 2, md: 2 }}
                    spacing="2"
                    mt="4"
                    mb="15"
                >
                    <Box mb="5">
                        {" "}
                        <Text
                            color={"black"}
                            mb="4px"
                            fontFamily={"ManropeSemiBold"}
                            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
                        > Additional Contact Information
                        </Text>
                    </Box>
                     <Box width="100%" textAlign="right">
                        <Button
                            colorScheme="teal"
                            background="black"
                            color="#ffffff"
                            _hover={{ color: "brand.500" }}
                            size="sm"
                            onClick={() => {
                                setStep(3)
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </SimpleGrid>

                <Box>
                    <FormControl isRequired>
                        <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Phone Number</FormLabel>
                        <Input
                            h="10"
                            borderRadius={"2em"}
                            border={"none"}
                            outlineColor={"black"}
                            maxLength={50}
                            color={"black"}
                            placeholder="Enter Phone Number"
                            defaultValue={user.phone}
                            onChange={(e) => {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    phone: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                </Box>


                <Box mt="5">
                    <FormControl>
                        <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Website</FormLabel>
                        <Input
                            h="10"
                            borderRadius={"2em"}
                            border={"none"}
                            outlineColor={"black"}
                            maxLength={250}
                            color={"black"}
                            placeholder="Enter Website Link"
                            defaultValue={user.website}
                            onChange={(e) => {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    website: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                </Box>


                <SimpleGrid
                    width="100%"
                    columns={{ base: 1, md: 3 }}
                    spacing="6"
                    mt="5"
                    mb="15"
                >
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Social Media Profile URL</FormLabel>
                            <Input
                                h="10"
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                maxLength={50}
                                color={"black"}
                                placeholder="Enter Social Media Profile URL"
                                value={socialUrl}
                                onChange={(e) => setSocialUrl(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Social Media Handle</FormLabel>
                            <Input
                                h="10"
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                maxLength={50}
                                color={"black"}
                                placeholder="Social Media Handle"
                                value={socialHandle}
                                onChange={(e) => setSocialHandle(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">&nbsp;</FormLabel>
                            <Button
                                onClick={handleAddMore}
                                width={"10em"}
                                border={"1px solid black"}
                                background={"black"}
                                borderRadius={"2em"}
                                color="#ffffff"
                                _hover={{ color: "brand.500", background: "black" }}>
                                Add
                            </Button>
                        </FormControl>
                    </Box>
                </SimpleGrid>
                {/* Display the added social media entries */}
                <Box mt="5" width="100%">
                    {socialMedia.map((item, index) => (
                        <>
                            <Flex>
                                <Flex key={index} p="2" mb="2">
                                    <Badge variant='solid' colorScheme='green'>Social URL</Badge>&nbsp;{item.socialUrl} &nbsp;&nbsp;
                                    <Badge variant='solid' colorScheme='green'>Social Handle</Badge>&nbsp;{item.socialHandle} &nbsp;&nbsp;
                                </Flex>
                                <Button
                                    onClick={() => handleRemove(index)}
                                    variant="solid"
                                    colorScheme="red"
                                    size="xs"
                                    mt="2"
                                    borderRadius="2em"
                                >
                                    Remove
                                </Button>
                            </Flex>
                        </>
                    ))}
                </Box>


                <Flex flex={1} marginTop={"5em"} justifyContent="space-between">
                    <Button onClick={() => {
                        setStep(1)
                    }} variant="light" width={"10em"}
                        border={"1px solid black"}
                        color="#000000"
                        _hover={{ color: "#000000" }}
                        bgColor={"white"}
                        borderRadius={"2em"}> Prev </Button>
                    <Button onClick={handleNextClick} width={"10em"}
                        border={"1px solid black"}
                        color="#ffffff"
                        _hover={{ color: "brand.500", background: "#000000" }}
                        bgColor={"black"}
                        borderRadius={"2em"}> Next </Button>
                </Flex>
            </Box>
        </>
    )
}