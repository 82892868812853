import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  Input,
  Text,
  Checkbox,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { CreateCountry, GetCountry, UpdateCountry } from "services/miscService";

export default function AddCountry() {
  const location = useLocation();
  const navigate = useNavigate();
  const countryId = location.state?.countryId;

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const [loading, setLoading] = useState(false);

  const [country, setCountry] = useState({
    countryName: "",
    countryAbbreviation: "",
  });

  const handleAddCountry = async () => {
    try {
      setLoading(true);
      const response =
        countryId !== null && countryId !== undefined
          ? await UpdateCountry(country, countryId, token)
          : await CreateCountry(country, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/app/settings/countries");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getCountry = async () => {
    try {
      setLoading(true);
      const response = await GetCountry(countryId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setCountry({
          countryName: response.data.countryName,
          countryAbbreviation: response.data.countryAbbreviation,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (countryId !== null && countryId !== undefined) {
      getCountry();
    }
  }, []);

  return (
    <>
      <Box pt={{ base: "120px", md: "120px", xl: "120px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Add Country
            </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/app/settings/countries");
              }}
            >
              Countries
            </Button>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="5"
          mb="10"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Country Name</FormLabel>
              <Input
                h="10"
                borderRadius={"2em"}
                border={"none"}
                outlineColor={"black"}
                maxLength={50}
                color={"black"}
                placeholder="Country Name"
                defaultValue={country.countryName}
                onChange={(e) => {
                  country.countryName = e.target.value;
                  setCountry(country);
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Country Abbreviation</FormLabel>
              <Input
                h="10"
                borderRadius={"2em"}
                border={"none"}
                outlineColor={"black"}
                maxLength={50}
                color={"black"}
                placeholder="country Abbreviation"
                defaultValue={country.countryAbbreviation}
                onChange={(e) => {
                  country.countryAbbreviation = e.target.value;
                  setCountry(country);
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <Box textAlign="left" mt="3" width="100%">
          <Button
            width={"10em"}
            border={"1px solid black"}
            color="#ffffff"
            _hover={{ color: "brand.500", background: "#000000" }}
            bgColor={"black"}
            borderRadius={"2em"}
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddCountry}
          >
            {countryId !== null && countryId !== undefined ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
