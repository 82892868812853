import { Box, Flex, SimpleGrid, Select, FormControl, FormLabel, Text, Input, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AccountSetup({ user, setUser, setStep, countries, roles }) {
    const userState = useSelector((state) => state?.user?.value);
    const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];

    const handleNextClick = () => {
        setStep(2);
    }

    return (
        <>
            <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="50">

                <SimpleGrid
                    width="100%"
                    columns={{ base: 2, md: 2 }}
                    spacing="2"
                    mt="4"
                    mb="15"
                >
                    <Box mb="5">
                        {" "}
                        <Text
                            color={"black"}
                            mb="4px"
                            fontFamily={"ManropeSemiBold"}
                            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
                        > Account Profile Setup
                        </Text>
                    </Box>
                    <Box width="100%" textAlign="right">
                        <Button
                            colorScheme="teal"
                            background="black"
                            color="#ffffff"
                            _hover={{ color: "brand.500" }}
                            size="sm"
                            onClick={() => {
                                setStep(2)
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </SimpleGrid>

                <SimpleGrid
                    width="100%"
                    columns={{ base: 1, md: 2 }}
                    spacing="6"
                    mb="15"
                >
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
                            <Input
                                h="10"
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                maxLength={50}
                                color={"black"}
                                placeholder="First Name"
                                defaultValue={user.firstName}
                                onChange={(e) => {
                                    setUser(prevUser => ({
                                        ...prevUser,
                                        firstName: e.target.value,
                                    }));
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
                            <Input
                                h="10"
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                maxLength={50}
                                color={"black"}
                                placeholder="Last Name"
                                defaultValue={user.lastName}
                                onChange={(e) => {
                                    setUser(prevUser => ({
                                        ...prevUser,
                                        lastName: e.target.value,
                                    }));
                                }}
                            />
                        </FormControl>
                    </Box>
                </SimpleGrid>


                <SimpleGrid
                    width="100%"
                    columns={{ base: 1, md: 2 }}
                    spacing="6"
                    mb="15"
                >
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Country</FormLabel>
                            <Select
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                placeholder="Select Option"
                                value={user.country}
                                onChange={(e) => {
                                    setUser(prevUser => ({
                                        ...prevUser,
                                        country: e.target.value
                                    }));
                                }}
                            >
                                {countries !== null && countries.map((country) => (
                                    <option
                                        key={country._id}
                                        value={country._id}
                                    >
                                        {country.countryName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">State</FormLabel>
                            <Input
                                h="10"
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                maxLength={50}
                                color={"black"}
                                placeholder="State"
                                defaultValue={user.state}
                                onChange={(e) => {
                                    setUser(prevUser => ({
                                        ...prevUser,
                                        state: e.target.value,
                                    }));
                                }}
                            />
                        </FormControl>
                    </Box>
                </SimpleGrid>

                {
                    priviledges.includes("Admin") &&
                    <SimpleGrid
                        width="100%"
                        columns={{ base: 1, md: 2 }}
                        spacing="6"
                        mb="15"
                    >
                        <Box>
                            <FormControl>
                                <FormLabel fontWeight="bold">Role</FormLabel>
                                <Select
                                   h="10"
                                   borderRadius={"2em"}
                                   border={"none"}
                                   outlineColor={"black"}
                                    placeholder="Select Option"
                                    value={user.role}
                                    onChange={(e) => {
                                        setUser(prevUser => ({
                                            ...prevUser,
                                            role: e.target.value
                                        }));
                                    }}
                                >
                                    {
                                         roles!==null && roles.length > 0 &&
                                        roles.map((role) => (
                                            <option key={role._id} value={role._id}>
                                                {role.roleName}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>

                        </Box>
                    </SimpleGrid>
                }

                <Flex flex={1} marginTop={"5em"} justifyContent={"flex-end"}>
                    <Button onClick={handleNextClick} width={"10em"}
                        border={"1px solid black"}
                        color="#ffffff"
                        _hover={{ color: "brand.500", background: "#000000" }}
                        bgColor={"black"}
                        borderRadius={"2em"}> Next </Button>
                </Flex>
            </Box>
        </>
    )
}