import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Button,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  InputRightElement,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  PinInput,
  PinInputField,
  Stack,
  VStack,
  Center,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronLeft, FiArrowLeft } from "react-icons/fi";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";

export default function ChangePassword() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const {
    isOpen: isOtpOpen,
    onOpen: onOtpOpen,
    onClose: onOtpClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  //const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");

  const [firstCode, setFirstCode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(false);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const backgroundColor = useColorModeValue("white", "brand.700");

  const changePassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        toast({
          title: "Passwords did not match, please check",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/changepassword",
        { newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
        onOtpOpen();
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      setResetLoading(true);
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/resetpassword",
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setResetLoading(false);
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setResetLoading(false);
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onOtpClose();
        setResetLoading(false);
        setLoading(false);
        navigate("/app/dashboard");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setResetLoading(false);
      setLoading(false);
    }
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/getOtp",
        { userId: userState._id },
        {
          headers: {},
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <>
      <Modal isOpen={isOtpOpen} onClose={onOtpClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onOtpClose}
              width="20px"
              height="20px"
              color={textColorPrimary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box>
                <Heading
                  as="h5"
                  color={textColorPrimary}
                  size={{ base: "sm", lg: "md" }}
                >
                  Enter OTP Code
                </Heading>
              </Box>

              <Box>
                <VStack spacing={4} align="stretch">
                  <Box w="100%" minH="40px" mt="4" p="4" borderRadius={"10"}>
                    <Center width="100%">
                      <PinInput otp>
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin1(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin2(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin3(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin4(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin5(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin6(e.target.value);
                          }}
                        />
                      </PinInput>
                      {showResendLink ? (
                        firstCode ? (
                          <Button size="xs" onClick={getOtp}>
                            Send
                          </Button>
                        ) : (
                          <Button size="xs" onClick={getOtp}>
                            Send
                          </Button>
                        )
                      ) : (
                        <Text>&nbsp;{remainingTime}s </Text>
                      )}
                    </Center>
                  </Box>

                  <Box w="100%" minH="40px" mt="4" p="4">
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      bg={useColorModeValue("brand.500", "gray.100")}
                      color={useColorModeValue("white", "brand.500")}
                      w="100%"
                      h="50"
                      isLoading={loading}
                      loadingText="Please Wait"
                      mb="24px"
                      _hover={{
                        bg: "blue.500",
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        resetPassword();
                      }}
                    >
                      Proceed
                    </Button>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mb="50">
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
          spacing="2"
          mb="3"
        >
          <Box textAlign="start">
            <Heading
              bg="inherit"
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
              fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
              mb="5"
            >
              Change Password
            </Heading>
          </Box>
          <Box textAlign="right">
            
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
          <Card align="center" p="20px">
            <Stack spacing={4} w={"full"} maxW={"md"} p={6} my={5}>
              {/* <FormControl id="password" isRequired>
              <FormLabel color={textColorSecondary}>Old Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} maxLength={100} 
                          onChange={(e)=>{
                            setPassword(e.target.value);
                          }} borderRadius="20" />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>*/}

              <FormControl id="password" isRequired>
                <FormLabel color={textColorSecondary} fontWeight="bold">
                  New Password
                </FormLabel>
                <InputGroup>
                  <Input
                    borderRadius="0"
                    type={showPassword ? "text" : "password"}
                    maxLength={100}
                    color={inputColor}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      color={inputColor}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel color={textColorSecondary} fontWeight="bold">
                  Confirm Password
                </FormLabel>
                <InputGroup>
                  <Input
                   borderRadius="0"
                    type={showPassword ? "text" : "password"}
                    maxLength={100}
                    color={inputColor}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                    }}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                borderRadius="0"
                variant="primary"
                bg={useColorModeValue("brand.500", "gray.100")}
                color={useColorModeValue("white", "brand.500")}
                isLoading={loading}
                loadingText="Wait"
                w="full"
                _hover={{
                  bg: "blue.500",
                  color: "white",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  changePassword();
                }}
              >
                Submit
              </Button>
            </Stack>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}

