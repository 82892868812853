import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, Stack, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";
import { UserLogin } from "services/userService";
import Logo from "./Logo";

const NavBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;

  const handleLogout = async () => {
      dispatch(setUser({}));
      navigate("/auth/login", { replace: true });
  };

  useEffect(() => {
  }, []);
  
 
  return (
    <NavBarContainer {...props}>
      <Logo
        w="100px"
        color={["white", "white", "primary.500", "primary.500"]}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks
      handleLogout={handleLogout}
      />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link to={to}>
      <Text
        display="block"
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
        fontFamily="ManropeRegular"
      >
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({
  handleLogout,
  isOpen,
}) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
          <Button
            zIndex="100"
            onClick={handleLogout}
            size="md"
            rounded={"full"}
            color="#000000"
            fontFamily="ManropeLight"
            bg="brand.500"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
             Logout
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="#000000"
            />
          </Button>
   
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Box width="100%" 
      background="black"
       shadow="md"
       height="40">
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pt={2}
            pb={2}
            pl={8}
            pr={8}
            bg={'#000000'}
            color={"#ffffff"}
            {...props}
          >
            {children}
          </Flex>
    </Box>
  );
};

export default NavBar;
