import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  SimpleGrid,
  Button,
  Icon,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Skeleton,
  SkeletonText,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiArrowLeft } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdDelete, MdOutlineImage, MdEditNote } from "react-icons/md";
import Card from "components/card/Card.js";
import { GetSkills, DeleteSkill } from "services/skillService";

export default function Skills() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [skills, setSkills] = useState([]);
  const [skillId, setSkillId] = useState("");
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];

  const toast = useToast();

  const getSkills = async () => {
    try {
      setLoading(true);
      if(!priviledges.includes("Admin")){
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await GetSkills(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setSkills(response?.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteSkill = async () => {
    try {
      setLoading(true);
      if(!priviledges.includes("Admin")){
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await DeleteSkill(skillId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Skill deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getSkills();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSkills();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              background="brand.500"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteSkill();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "80px", md: "120px", xl: "130px" }} width="100%" mb="70">

      <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Skills
            </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/app/settings/addskill");
              }}
            >
              Add Skill
            </Button>
          </Box>
        </SimpleGrid>


        {loading ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#000000">Skill Name</Th>
                  <Th color="#000000">Skill Abbreviation</Th>
                  <Th color="#000000">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {skills !== null &&
                  skills.map((skill) => (
                    <Tr>
                      <Td>{skill.skillName}</Td>
                      <Td>{skill.skillAbbreviation}</Td>
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="primary"
                            background="#000000"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate("/app/settings/addskill/" + skill._id, {
                                  state: { skillId: skill._id },
                                });
                              }}
                            >
                              Edit &nbsp;{" "}
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="#000000"
                              />{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setSkillId(skill._id);
                                onOpen();
                              }}
                            >
                              Delete &nbsp;{" "}
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="#000000"
                              />{" "}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
}

const Loading = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
