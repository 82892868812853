import {Flex,Text} from "@chakra-ui/react"

export default function PlaceholderLogoImage(){
    return(
        <Flex height={"7em"} width={"7em"} border={"1em solid white"} justifyContent={"center"} alignItems={"center"} padding={"1em"}>
                <Text fontSize={"xx-large"} fontWeight={"bold"} backgroundColor={"yellow"}>
                    ASA
                </Text>
        </Flex>
    )
}