import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiHome } from "react-icons/fi";
import {
  FaCog
} from "react-icons/fa";
import { IoIosFootball } from "react-icons/io";

import Dashboard from "views/app/dashboard";
import Users from "views/app/user";
import Details from "views/app/user/details";

import SettingsLayout from "views/app/settings/layout";
import Profile from "views/app/settings/index";
import Roles from "views/app/settings/roles";
import AddRole from "views/app/settings/addrole";
import Skills from "views/app/settings/skills";
import AddSkill from "views/app/settings/addskill";
import Countries from "views/app/settings/countries";
import AddCountry from "views/app/settings/addcountry";
import ChangePassword from "views/app/settings/changepassword";
import AccountSetup from "views/app/settings/accountsetup";

const routes = [
  {
    name: "Archivist Directory",
    nameAbbrv: "Archivist Directory",
    layout: "/app",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" color="#000000" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Admin", "Member"],
  },
  {
    name: "Account & Settings",
    nameAbbrv: "Settings",
    layout: "/app",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" color="#000000" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Member"],
    items: [
      {
        name: "Profile",
        layout: "/app",
        path: "index",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <Profile />,
        collapse: false,
        show: true,
        allow: ["Admin", "Member"],
      },
      {
        name: "User Roles",
        layout: "/app",
        path: "roles",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Add Role",
        layout: "/app",
        path: "addrole",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Add Role",
        layout: "/app",
        path: "addrole/:roleId",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Skills",
        layout: "/app",
        path: "skills",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <Skills />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Add Skill",
        layout: "/app",
        path: "addskill",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddSkill />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Add Skill",
        layout: "/app",
        path: "addskill/:skillId",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddSkill />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Countries",
        layout: "/app",
        path: "countries",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <Countries />,
        collapse: false,
        show: true,
        allow: ["Admin"],
      },
      {
        name: "Add Country",
        layout: "/app",
        path: "addcountry",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddCountry />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Add Country",
        layout: "/app",
        path: "addcountry/:countryId",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AddCountry />,
        collapse: false,
        show: false,
        allow: ["Admin"],
      },
      {
        name: "Change password",
        layout: "/app",
        path: "changepassword",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <ChangePassword />,
        collapse: false,
        show: true,
        allow: ["Admin", "Member"],
      },
      {
        name: "Account Setup",
        layout: "/app",
        path: "setup",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AccountSetup />,
        collapse: false,
        show: false,
        allow: ["Admin", "Member"],
      },
      {
        name: "Account Setup",
        layout: "/app",
        path: "setup/:userId",
        icon: (
          <Icon as={IoIosFootball} width="20px" height="20px" color="white" />
        ),
        component: <AccountSetup />,
        collapse: false,
        show: false,
        allow: ["Admin", "Member"],
      },
    ],
  },
  {
    name: "Pending Accounts",
    nameAbbrv: "Pending Accounts",
    layout: "/app",
    path: "user",
    icon: <Icon as={FiUsers} width="20px" height="20px" color="#000000" />,
    component: <Users />,
    collapse: false,
    allow: ["Admin"],
  },
  {
    name: "Pending Accounts",
    nameAbbrv: "Pending Accounts",
    layout: "/app",
    path: "user/details",
    icon: <Icon as={FiUsers} width="20px" height="20px" color="#000000" />,
    component: <Details />,
    collapse: false,
    allow: ["Admin"],
    show: false
  },
];

export default routes;
