import { Box, Flex, Textarea, Radio, SimpleGrid, FormControl, FormLabel, Text, Input, Icon, Circle, Button } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

export default function AvailabilitySetup({ user, setUser, setStep, handleSubmit }) {
    const navigate = useNavigate();

    return (
        <>
            <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="50">
                <SimpleGrid
                    width="100%"
                    columns={{ base: 2, md: 2 }}
                    spacing="2"
                    mt="4"
                    mb="15"
                >
                    <Box mb="5">
                        {" "}
                        <Text
                            color={"black"}
                            mb="4px"
                            fontFamily={"ManropeSemiBold"}
                            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
                        > Additional Contact Information
                        </Text>
                    </Box>
                    <Box width="100%" textAlign="right">
                        <Button
                            colorScheme="teal"
                            background="black"
                            color="#ffffff"
                            _hover={{ color: "brand.500" }}
                            size="sm"
                            onClick={() => {
                                setStep(3)
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </SimpleGrid>

                <Box width="100%">

                    <Flex direction={"column"} gap={"1em"}>

                        <Flex flexDirection={"column"} gap={"2em"}>
                            <Text noOfLines={2}>
                                Does your Archival practice employ anti-racist frameworks, ethics of care, consent model, and other relevant models for ethical archiving
                            </Text>
                            <Flex gap={"2em"}>
                                <Flex gap={"10px"}>
                                    <Radio id="ethicalFramework" isChecked={user.hasEthicalPractices === "yes"} onClick={() => {
                                        setUser(prevUser => ({
                                            ...prevUser,
                                            hasEthicalPractices: "yes",
                                        }));
                                    }} />
                                    Yes
                                </Flex>
                                <Flex gap={"10px"}>
                                    <Radio id="ethicalFramework" isChecked={user.hasEthicalPractices === "no"} onClick={() => {
                                        setUser(prevUser => ({
                                            ...prevUser,
                                            hasEthicalPractices: "no",
                                        }));
                                    }} />
                                    No
                                </Flex>
                                <Flex gap={"10px"}>
                                    <Radio id="ethicalFramework" isChecked={user.hasEthicalPractices === "reading"}
                                        onClick={() => {
                                            setUser(prevUser => ({
                                                ...prevUser,
                                                hasEthicalPractices: "reading",
                                            }));
                                        }} />
                                    I am reading up on the literature to learn how to practice ethical archiving
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"} gap={"2em"}>
                            <Text noOfLines={2}>
                                How many Hours are you willing to donate to Activists
                            </Text>
                            <Flex gap={"2em"}>
                                <Flex gap={"10px"}>
                                    <Radio isChecked={user.weeklyAvailability === "1-3"}
                                        onClick={() => {
                                            setUser(prevUser => ({
                                                ...prevUser,
                                                weeklyAvailability: "1-3",
                                            }));
                                        }} />
                                    1-3
                                </Flex>
                                <Flex gap={"10px"}>
                                    <Radio isChecked={user.weeklyAvailability === "4-7"} onClick={() => {
                                        setUser(prevUser => ({
                                            ...prevUser,
                                            weeklyAvailability: "4-7",
                                        }));
                                    }} />
                                    4-7
                                </Flex>
                                <Flex gap={"10px"}>
                                    <Radio isChecked={user.weeklyAvailability === "8+"}
                                        onClick={() => {
                                            setUser(prevUser => ({
                                                ...prevUser,
                                                weeklyAvailability: "8+",
                                            }));
                                        }} />
                                    8+
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"} gap={"2em"}>
                            <Text noOfLines={2}>
                                Do you work with the law enforcement in any way, shape or form
                            </Text>
                            <Flex gap={"2em"}>
                                <Flex gap={"10px"}>
                                    <Radio isChecked={user.lawEnforcementAffiliated}
                                        onClick={() => {
                                            setUser(prevUser => ({
                                                ...prevUser,
                                                lawEnforcementAffiliated: true,
                                            }));
                                        }} />
                                    Yes
                                </Flex>
                                <Flex gap={"10px"}>
                                    <Radio isChecked={!user.lawEnforcementAffiliated}
                                        onClick={() => {
                                            setUser(prevUser => ({
                                                ...prevUser,
                                                lawEnforcementAffiliated: false,
                                            }));
                                        }} />
                                    No
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"} gap={"2em"}>
                            <Text noOfLines={2}>
                                If yes, Please describe
                            </Text>
                            <Textarea resize={"vertical"} onChange={(e) => {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    lawEnforcement: e.target.value,
                                }));
                            }} />
                        </Flex>
                    </Flex>

                </Box>

                <Flex flex={1} marginTop={"5em"} justifyContent="space-between">
                    <Button onClick={() => {
                        setStep(3)
                    }} variant="light" width={"10em"}
                        border={"1px solid black"}
                        color="#000000"
                        _hover={{ color: "#000000" }}
                        bgColor={"white"}
                        borderRadius={"2em"}> Prev </Button>
                    <Button onClick={handleSubmit} width={"10em"}
                        border={"1px solid black"}
                        color="#ffffff"
                        _hover={{ color: "brand.500", background: "#000000" }}
                        bgColor={"black"}
                        borderRadius={"2em"}> Submit </Button>
                </Flex>
            </Box>
        </>
    )
}