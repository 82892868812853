import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Tag,
  Button,
  Heading,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  SkeletonText,
  Flex,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GetCountries, GetSkills } from "services/miscService";
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import Card from "components/card/Card.js";
import {
  SearchUser,
  GetUsers,
  DeleteUser,
  UpdateUserByID
} from "services/userService";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiArrowLeft } from "react-icons/fi";
import { MdDelete, MdEditNote } from "react-icons/md";

export default function Index() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isApproveOpen, onOpen:onApproveOpen, onClose:onApproveClose } = useDisclosure();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];

  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [user, setUser] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState(null);
  const [skills, setSkills] = useState(null);
  const [country, setCountry] = useState(null);
  const [skill, setSkill] = useState(null);
  const [userId, setUserId] = useState("");

  const getUsers = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        accountVerified: true,
        deleted: false,
        profileCompleted: true,
        directoryVisibility: false,
        isAdmin: false
      };
      const response = await GetUsers(pagination, filter, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setUsers(response?.data?.users);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };


  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      if(!priviledges.includes("Admin")){
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await UpdateUserByID(user, userId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      getUsers();
      onApproveClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const searchData = {
        searchText,
        accountVerified: true,
        deleted: false,
        profileCompleted: true,
        directoryVisibility: false,
        isAdmin: false
      };
      const response = await SearchUser(searchData, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setLoading(false);
        setUsers(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      if (!priviledges.includes("Admin")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const response = await DeleteUser(userId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "User deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUsers();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries(token);
      setLoading(false);
      setCountries(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const getSkills = async () => {
    try {
      setLoading(true);
      const response = await GetSkills(token);
      setLoading(false);
      setSkills(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    getSkills();
    getCountries();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
           <Button colorScheme='blue' mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <Modal isOpen={isApproveOpen} onClose={onApproveClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onApproveClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to approve this user?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onApproveClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                handleUpdateUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <Box mt={{ base: "120px", md: "150px", xl: "150px" }} width="100%" mb="70">
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          w="100%"
          p="2"
          spacing="2"
          mb="4"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Pending Accounts
            </Text>
          </Box>
          <Box textAlign="right">
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ sm: 1, md: 4 }}
          w="100%"
          p="2"
          spacing="5"
          mt="3"
        >
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <TextFilter
            searchText={searchText}
            setSearchText={setSearchText}
            searchTextLabel="Email/Phone/Last Name"
            initFilterSubmit={initFilterSubmit}
          />

        </SimpleGrid>

        {loading ? (
          <LoadingUsers />
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#000000">Last Name</Th>
                  <Th color="#000000">First Name</Th>
                  <Th color="#000000">E-mail Address</Th>
                  <Th color="#000000">Social Media</Th>
                  <Th color="#000000">State/Providence</Th>
                  <Th color="#000000">Country</Th>
                  <Th color="#000000">Skill/Resource</Th>
                  <Th color="#000000">Week Hours Availability</Th>
                  <Th color="#000000">Work in Law Enforcement</Th>
                  {priviledges.includes("Admin") &&
                   <Th color="#000000">Action</Th>
                  }
                </Tr>
              </Thead>
              <Tbody>
                {users !== null &&
                  users.map((user) => (
                    <Tr>
                      <Td>{user.lastName}</Td>
                      <Td>{user.firstName}</Td>
                      <Td>{user.email}</Td>
                      <Td>
                        <Flex gap={"10px"} flexWrap={"wrap"}>
                          {
                            user?.socialMedia.map((social, key)=>(
                            <Tag key={key} gap={"1em"} background="brand.500">
                                <Text fontWeight={"bold"}>
                                    {social.socialHandle}
                                </Text>
                            </Tag>
                                ))
                            }
                        </Flex>
                      </Td>
                      <Td>{user.state}</Td>
                      <Td>{user?.state?.stateName}</Td>
                      <Td>
                         <Flex gap={"10px"} flexWrap={"wrap"}>
                          {
                            user?.skill.map((skill)=>(
                            <Tag key={skill._id} gap={"1em"} background="brand.500">
                                <Text fontWeight={"bold"}>
                                    {skill.skillName}
                                </Text>
                            </Tag>
                                ))
                            }
                        </Flex>
                      </Td>
                      <Td>{user?.weeklyAvailability}</Td>
                      <Td>{user?.lawEnforcementAffiliated?<Text color={"green"} fontWeight={"bold"}>Yes</Text>:<Text fontWeight={"bold"} color={"orange"}>No</Text>}</Td>
                      {priviledges.includes("Admin") &&
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="primary"
                            background="black"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                           <MenuList>
                            <MenuItem
                              onClick={() => {
                                setUserId(user._id);
                                const userData = {directoryVisibility: true};
                                setUser(userData);
                                onApproveOpen();
                              }}
                            >
                              Approve &nbsp;{" "}
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="black"
                              />{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                navigate("/app/settings/setup/" + user._id, {
                                  state: { userId: user._id },
                                });
                              }}
                            >
                              Edit &nbsp;{" "}
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="black"
                              />{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setUserId(user._id);
                                //setUser(user);
                                onOpen();
                              }}
                            >
                              Delete &nbsp;{" "}
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="black"
                              />{" "}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td> 
                      }
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getUsers}
            />
          </Box>
        )}
      </Box>
    </>
  );
};


const LoadingUsers = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};