import { Box, Flex, Stack, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function SetupComplete(){
    const navigate = useNavigate()

    return(
        <>
            <Flex width={"100%"} direction={"column"} height={"100%"}>
                <Box>
                    <Text fontWeight={"bold"} fontSize={"x-large"}>
                        Profile Complete
                    </Text>
                    <Stack direction={"column"} gap={"2em"}>
                        <Text>
                            Thank you for Joining! We need to continue building strong networks of archivists to support activist
                        </Text>
                        <Text>
                            Your profile have been completed and will be reviewed by an administrator for direct approval. if you have any questions or concern, please contact us xxxx@xxxxx.io
                        </Text>
                    </Stack>
                    <Stack marginTop={"4em"} direction={"row"} gap={"3em"}>
                       <Button variant="light" width={"10em"}
                        border={"1px solid black"}
                        bgColor={"black"}
                        borderRadius={"2em"}> Profile </Button>

                        <Button variant="light" width={"10em"}
                        border={"1px solid black"}
                        bgColor={"black"}
                        borderRadius={"2em"}> Archivist Directory </Button>


                       <Button variant="light" width={"10em"}
                        border={"1px solid black"}
                        bgColor={"black"}
                        borderRadius={"2em"}> Slack Channel </Button>

                    </Stack>
                </Box>
            </Flex>
        </>
    )
}