import React from "react";
import { Box, Text, Heading, Flex, Image} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/web/index">
      <Box mt="5" style={{
            backgroundColor:"skyblue",
            padding:"0.5em",
            color:"white",
            borderRadius:"10px" 
        }}>
            <Text fontSize={"x-large"} fontWeight={"bold"}>
                DN
            </Text>
        </Box>
    </Link>
  );
}
