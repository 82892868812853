import { Link as ReactRouterLink, useNavigate, Outlet } from "react-router-dom";
import {
  Box,
  Text,
  HStack,
  Flex,
  Image,
  Center,
  Icon,
  Link as ChakraLink,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import routes from "routesuser.js";
import PlaceholderLogoImage from "components/navigation/PlaceholderLogoImage.js";
import Footer from "components/navigation/Footer.js";
import NavBar from "components/navigation/NavBarUser.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Logo from 'assets/images/logo.png';


const UserLayout = () => {
  // Use media query to check the screen size
  const toast = useToast();
  const navigate = useNavigate();
  const [isLargerThanMedium] = useMediaQuery("(min-width: 48em)");
  const [isSmallerThanMedium] = useMediaQuery("(max-width: 47.99em)");
  const [toggle, setToggle] = useState({});

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;
  const token = user.hasOwnProperty("token") ? user.token : null;
  const priviledges = user.hasOwnProperty("priviledges") ? user.priviledges : [];


  const handleToggle = (route) => {
    const updatedToggle = {};
    for (const toggleKey in toggle) {
      if (toggle.hasOwnProperty(toggleKey)) {
        // Set the value to 'none' if the key doesn't match the passed key
        updatedToggle[toggleKey] = toggleKey !== route ? false : toggle[toggleKey];
      }
    }
    // Update the state with the updatedToggle object
    setToggle(updatedToggle);
    setToggle((prevState) => ({
      ...prevState,
      [route]: !prevState[route], // Toggle the state of the submenu
    }));

  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/auth/index", { replace: true });
    }
  });

  return (
    <Box>
      <HStack width="100%" background="none" position="fixed" zIndex="100" >
        <NavBar />
      </HStack>
      {/* Sidebar or Footerbar based on screen size */}
      {isLargerThanMedium ? (
        <Box
          as="aside"
          w="300px"
          h="100vh"
          bg="brand.500"
          color="#000000"
          zIndex="10"
          position="fixed"
          top="40"
          left="0"
          paddingTop="10"
          overflowX={'scroll'}
        >

          <Center width="100%" mb="10">
            {/*<Image
              src={Logo}
              width={120}
              height={120}
              alt="District"
              alignSelf={"center"}
            />*/}
            <PlaceholderLogoImage/>
          </Center>

          {routes.map((route) => (
            <>
              {!route.collapse && (
                <NavItem key={route.name} route={route} priviledges={priviledges}>
                  {route.name}
                </NavItem>
              )}

              {route.collapse && (
                <>
                  <Box onClick={() => handleToggle(route.name)} pl="2">
                    <ParentMenu
                      key={route.name}
                      route={route}
                      priviledges={priviledges}
                    >
                      {route.name}
                    </ParentMenu>
                  </Box>
                  <Box
                    pl="3"
                    pt="1"
                    pb="2"
                    width="100%"
                    background={"brand.800"}
                    display={toggle[route.name] ? "block" : "none"}
                  >
                    <SubMenu
                      routes={route.items}
                      path={route.path}
                      priviledges={priviledges}
                    />
                  </Box>
                </>
              )}
            </>
          ))}
        </Box>
      ) : (
        isSmallerThanMedium && (
          <Box
            as="footer"
            w="100%"
            h="80px"
            bg="brand.500"
            color="#000000"
            position="fixed"
            bottom="0"
            left="0"
            paddingTop="0"
            zIndex="100"
          >
            <Flex minWidth='max-content' alignItems='center' justifyContent='space-between'>
              {routes.map((route) => (
                <NavItemSmall
                  key={route.name}
                  route={route}
                  priviledges={priviledges}
                ></NavItemSmall>
              ))}
            </Flex>
          </Box>
        )
      )}

      {/* Main content */}
      <Box ml={isLargerThanMedium ? "300px" : "0"} minH="100vh" bg="white" zIndex={'0'}>
        <Box p="4" pt="10" width="100%">
          <Outlet />
        </Box>
        {/*<Footer />*/}
      </Box>
    </Box>
  );
};

const SubMenu = ({ routes, path, priviledges }) => {
  return (
    <>
      {routes.map((route) => (
        <>
          {route.show && (
            <SubNavItem
              key={route.name}
              route={route}
              path={path}
              priviledges={priviledges}
            >
              {route.name}
            </SubNavItem>
          )}
        </>
      ))}
    </>
  );
};

const ParentMenu = ({ route, priviledges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <Box mt="4" mb="4" width="100%" fontSize="17" color="#000000" textAlign="center">
          <Text fontFamily="ManropeRegular"
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>{children}</Text>
        </Box>
      )}
    </>
  );
};

const SubNavItem = ({ route, path, priviledges, children }) => {
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + path + "/" + route.path}
        >
          <Box
            pl="2"
            mt="4"
            mb="4"
            width="100%"
            fontSize="17"
            color="#000000"
            textAlign="center"
          >
            <Text fontFamily="ManropeRegular"
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}> &raquo; {children}</Text>
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

const NavItem = ({ route, priviledges, children }) => {
  //const allowed = route.allow;
  //alert(JSON.stringify(allowed));
  //const isRoleInArray = allowed.includes(userType) || allowed.includes("all");
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + route.path}
        >
          <Box
            pl="2"
            mt="4"
            mb="4"
            width="100%"
            color="#000000"
            textAlign="center"
          >
            <Text fontFamily="ManropeRegular"
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>{children}</Text>
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

const NavItemSmall = ({ route, priviledges, children }) => {
  //const allowed = route.allow;
  //const isRoleInArray = allowed.includes(userType) || allowed.includes("all");
  const allowed = route.allow;
  const isRoleInArray = allowed.some(element => priviledges.includes(element));
  return (
    <>
      {isRoleInArray && (
        <ChakraLink
          as={ReactRouterLink}
          to={route.layout + "/" + route.path}
          mt="25"
        >
          <Box
            mt="4"
            width="100%"
            fontSize="17"
            color="#000000"
            align="center"
            mr="5"
          >
            {route.icon}
            <Text color="#000000">{route.linkText}</Text>
          </Box>
        </ChakraLink>
      )}
    </>
  );
};

export default UserLayout;
