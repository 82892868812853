import React, { useState } from 'react';
import { Box, Badge, Select, Flex, SimpleGrid, FormControl, FormLabel, Text, Input, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function SkillsSetup({ user, setUser, setStep, allSkills }) {
    const navigate = useNavigate();
    const handleNextClick = () => {
        setStep(4)
    }
    const [skills, setSkills] = useState(user.skill);
    const [currentSkill, setCurrentSkill] = useState('');

    const handleAddSkill = () => {
        if (currentSkill.trim() !== '') {
            const skillObj = allSkills.find(skill => skill._id === currentSkill);
            if (skillObj && !skills.some(skill => skill._id === skillObj._id)) {
                setSkills([...skills, skillObj]);
                setUser(prevUser => ({
                    ...prevUser,
                    skill: [...skills, skillObj]
                }));
                setCurrentSkill(''); // clear the input field
            }
           
        }
    };

    const handleRemoveSkill = (id) => {
        const updatedSkills = skills.filter((skill) => skill._id !== id);
        setSkills(updatedSkills);
        setUser(prevUser => ({
            ...prevUser,
            skill: skills
        }));
    };

    return (
        <>
            <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" mb="50">
                <SimpleGrid
                    width="100%"
                    columns={{ base: 2, md: 2 }}
                    spacing="2"
                    mt="4"
                    mb="15"
                >
                    <Box mb="5">
                        {" "}
                        <Text
                            color={"black"}
                            mb="4px"
                            fontFamily={"ManropeSemiBold"}
                            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
                        > Skills/Resources
                        </Text>
                    </Box>
                    <Box width="100%" textAlign="right">
                      <Button
                            colorScheme="teal"
                            background="black"
                            color="#ffffff"
                            _hover={{ color: "brand.500" }}
                            size="sm"
                            onClick={() => {
                                setStep(4)
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </SimpleGrid>

                <SimpleGrid
                    width="100%"
                    columns={{ base: 1, md: 3 }}
                    spacing="6"
                    mt="5"
                    mb="15"
                >
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Skills/Resources</FormLabel>
                            <Select
                                borderRadius={"2em"}
                                border={"none"}
                                outlineColor={"black"}
                                placeholder="Select Option"
                                value={currentSkill}
                                onChange={(e) => setCurrentSkill(e.target.value)}
                            >
                                {allSkills !== null && allSkills.map((skill) => (
                                    <option
                                        key={skill._id}
                                        value={skill._id}
                                    >
                                        {skill.skillName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">&nbsp;</FormLabel>
                            <Button
                                onClick={handleAddSkill}
                                width={"10em"}
                                border={"1px solid black"}
                                color="#ffffff"
                                background="#000000"
                                _hover={{ color: "#ffffff", background: "#000000" }}
                                borderRadius={"2em"}
                            >
                                Add
                            </Button>
                        </FormControl>
                    </Box>
                </SimpleGrid>
                {/* Display the added skills */}
                <Box mt="5">
                    {skills !== null && skills.map((skill, index) => (
                        <Flex>
                            <Flex key={index} p="2" mb="2">
                                <Badge variant='solid' colorScheme='green'>Skill</Badge>&nbsp;{skill.skillName} &nbsp;&nbsp;
                            </Flex>
                            <Button
                                onClick={() => handleRemoveSkill(skill._id)}
                                variant="solid"
                                colorScheme="red"
                                size="xs"
                                mt="2"
                                borderRadius="2em"
                            >
                                Remove
                            </Button>
                        </Flex>
                    ))}
                </Box>


                <Flex flex={1} marginTop={"5em"} justifyContent="space-between">
                    <Button onClick={() => {
                        setStep(2)
                    }} variant="light" width={"10em"}
                        border={"1px solid black"}
                        color="#000000"
                        _hover={{ color: "#000000" }}
                        bgColor={"white"}
                        borderRadius={"2em"}> Prev </Button>
                    <Button onClick={handleNextClick} variant="light" width={"10em"}
                        border={"1px solid black"}
                        color="#ffffff"
                        _hover={{ color: "brand.500" }}
                        bgColor={"black"}
                        borderRadius={"2em"}> Next </Button>
                </Flex>
            </Box>
        </>
    )
}