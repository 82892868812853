import axiosInstance from "./axiosInstance"; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetSkill = async (skillId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/skill/" + skillId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSkills = async (token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/skill",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const DeleteSkill = async (skillId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/skill/" + skillId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const CreateSkill = async (skill, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/skill", skill, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateSkill = async (skillData, skillId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/skill/" + skillId,
      skillData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export { GetSkill, GetSkills, DeleteSkill, CreateSkill, UpdateSkill };
