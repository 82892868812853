import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
//import Logo from "assets/images/logogold.png";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { UserRegister } from "services/userService";

function SignUp() {
  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const inputColor = useColorModeValue("brand.500", "gray.100");

  const register = async () => {
    try {
      if (!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const user = {
        email,
        password,
      }

      setLoading(true);
      const response = await UserRegister(user);
      setLoading(false);

      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/auth/index", { state: { email: email } });
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, "#000000", "#ffffff", "upper");
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up | Create an account</title>
        <meta name="description" content=" Create an account" />
        <meta name="keywords" content="" />
      </Helmet>
      <DefaultAuth illustrationBackground={''} image={''}>
        <Flex
          maxW={{ base: "100%", md: "100%" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          mb="10"
          h="100%"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex flex={1} direction={"column"} justifyContent={"center"} alignItems={"center"} padding={"2em"}>
            <Text fontWeight={"bold"} fontFamily={"ManropeSemiBold"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
              ARCHIVIST SUPPORTING ACTIVISTS
            </Text>
            <Text marginBlock={"0.3em"} textAlign={"center"} fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
              In response to the ongoing police violence at protest in the wake of minneapolis police murder of George Floyd, Documenting the now put out a call for archivists and memory workers to help support activists documenting this violence. These archivist-volunteers practice non-extractive, anti-racist ethical approaches to achiving, and have indicated in any way if they are connected to the law enforcement.
            </Text>
          </Flex>
          <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>

            <Stack
              spacing={8}
              mx={"auto"}
              w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
            >
              <Stack align={"center"}>

                <Heading
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                  textAlign={"center"}
                  fontFamily="ManropeSemiBold"
                >
                  Archivist Sign-up
                </Heading>
              </Stack>
              <Box rounded={"lg"} p={2}>
                <Stack spacing={4}>
                  <FormControl id="email" isRequired>
                    <FormLabel fontFamily="ManropeSemiBold">Email Address</FormLabel>
                    <Input
                      type="email"
                      borderRadius={"2em"}
                      border={"none"}
                      outlineColor={"black"}
                      maxLength={50}
                      color={"black"}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl id="password" isRequired>
                    <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        maxLength={100}
                        borderRadius={"2em"}
                        border={"none"}
                        outlineColor={"black"}
                        color={"black"}
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          bg={useColorModeValue("brand.500", "gray.100")}
                          color={useColorModeValue("black", "brand.500")}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>


                  <FormControl id="password" isRequired>
                    <FormLabel fontFamily="ManropeSemiBold">Confirm Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        maxLength={100}
                        borderRadius={"2em"}
                        border={"none"}
                        outlineColor={"black"}
                        color={"black"}
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          bg={useColorModeValue("brand.500", "gray.100")}
                          color={useColorModeValue("black", "brand.500")}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Stack spacing={10} pt={2}>
                    <Flex
                      columns={{ sm: 2, md: 2 }}
                      direction={{ sm: "row", md: "row" }}
                    >
                      <Box>
                        <LoadCanvasTemplate reloadText="Reload" />
                      </Box>
                      <Box>
                        <Input
                          borderRadius="0"
                          type={"text"}
                          defaultValue={captchaToken}
                          height="30"
                          maxLength={6}
                          onChange={(e) => {
                            setCaptchaToken(e.target.value);
                          }}
                          color={"black"}
                          placeholder="Enter Captcha"
                        />
                      </Box>
                    </Flex>

                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      border={"1px solid black"}
                      bgColor={"black"}
                      borderRadius={"2em"}
                      w="100%"
                      h="50"
                      isLoading={loading}
                      loadingText="Please Wait"
                      mb="10px"
                      onClick={(e) => {
                        e.preventDefault();
                        register();
                      }}
                    >
                      <b>Sign Up</b>
                    </Button>
                  </Stack>
                  <Stack pt={0}>
                    <Text align={"center"}>
                      <Link color={"blue.400"} to="/auth/index">
                        <u>
                          <b>Login</b>
                        </u>
                      </Link>&nbsp;|&nbsp;
                      <Link color={"blue.400"} to="/auth/forgot">
                        <u>
                          <b>Reset Password</b>
                        </u>
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignUp;
