import React from "react";
import ReactPaginate from "react-paginate";

export default function Paginate(prop) {
  const { setFrom, pageCount, getRecords } = prop;
  const handlePageClick = (event) => {
    setFrom(event.selected);
    getRecords(event.selected);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
    </>
  );
}
