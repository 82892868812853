// axiosInstance.js
import axios from 'axios';

const instance = axios.create();
// Add a request interceptor
instance.interceptors.request.use((config) => {
  // You can modify the request headers here if needed
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
  // Return the response if it's successful
  return response;
}, (error) => {
  // Check if the error is due to JWT expiration
  if (error.response && error.response.status === 505) {
    throw new Error("Login expired, reconnect wallet");
    //redirect to a react route
  }else{
      // Return the original error
      return Promise.reject(error);
  }
});

export default instance;
