import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText
} from "@chakra-ui/react";
import Card from "components/card/Card";

export default function TextFilter(props) {
  const { searchText, setSearchText, searchTextLabel, initFilterSubmit } = props;
  return (
    <Box
      w='100%'
      mb='20px'
    >
      <FormControl>
        <FormLabel htmlFor='date' fontWeight="bold">Filter By {searchTextLabel} </FormLabel>
        <Input borderRadius={"2em"}
          border={"none"}
          outlineColor={"black"} type='text' defaultValue={searchText} onChange={(e) => setSearchText(e.target.value)} />
      </FormControl>
      <Button
        mt="3"
        width={"10em"}
        border={"1px solid black"}
        color="#ffffff"
        _hover={{ color: "brand.500", background: "#000000" }}
        bgColor={"black"}
        borderRadius={"2em"}
        onClick={(e) => { e.preventDefault(); initFilterSubmit(); }} >
        Search
      </Button>
    </Box>
  );
}