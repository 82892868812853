import axiosInstance from './axiosInstance'; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetStates = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/state", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const GetCitys = async (token) => {
  try {
    const response = await axiosInstance.get( SERVER_URL+"/city", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetCountries = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/country", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSkills = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/skill", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetCountry = async (countryId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/country/" + countryId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteCountry = async (countryId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/country/" + countryId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateCountry = async (country, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/country", country, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateCountry = async (countryData, countryId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/country/" + countryId,
      countryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export {
  GetStates,
  GetCitys,
  GetCountries,
  GetSkills,
  GetCountry,
  DeleteCountry,
  CreateCountry,
  UpdateCountry
};




