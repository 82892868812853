import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  HStack,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Forgot() {
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const inputColor = useColorModeValue("brand.500", "gray.100");

  const forgot = async () => {
    try {
      if (!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/forgot",
        { email },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setLoading(false);
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setEmail(response.data.email);
        navigate("/auth/otp", { state: { email: response.data.email } });
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, "#000000", "#ffffff", "upper");
  }, []);

  return (
    <DefaultAuth illustrationBackground={''} image={''}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        mb="10"
        h="100%"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex flex={1} direction={"column"} justifyContent={"center"} alignItems={"center"} padding={"2em"}>
            <Text fontWeight={"bold"} fontFamily={"ManropeSemiBold"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
              ARCHIVIST SUPPORTING ACTIVISTS
            </Text>
            <Text marginBlock={"0.3em"} textAlign={"center"} fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
              In response to the ongoing police violence at protest in the wake of minneapolis police murder of George Floyd, Documenting the now put out a call for archivists and memory workers to help support activists documenting this violence. These archivist-volunteers practice non-extractive, anti-racist ethical approaches to achiving, and have indicated in any way if they are connected to the law enforcement.
            </Text>
          </Flex>

        <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
          <Stack
            spacing={8}
            mx={"auto"}
            w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
          >
            <Stack align={"center"}>
              <Heading
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                textAlign={"center"}
                fontFamily="ManropeSemiBold"
              >
                Forgot Password
              </Heading>
            </Stack>
            <Box rounded={"lg"} p={2} width="100%">
              <Stack spacing={4} width="100%">
                <Box width="100%">
                  <FormControl id="email" isRequired>
                    <FormLabel fontFamily="ManropeSemiBold">
                      Email address
                    </FormLabel>
                    <Input
                      type="email"
                      borderRadius={"2em"}
                      border={"none"}
                      outlineColor={"black"}
                      maxLength={50}
                      color={"black"}
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                </Box>

                <Stack spacing={10} pt={2}>
                  <Flex
                    columns={{ sm: 2, md: 2 }}
                    direction={{ sm: "row", md: "row" }}
                  >
                    <Box>
                      <LoadCanvasTemplate reloadText="Reload" />
                    </Box>
                    <Box>
                      <Input
                        borderRadius="0"
                        type={"text"}
                        defaultValue={captchaToken}
                        height="30"
                        maxLength={6}
                        color="black"
                        onChange={(e) => {
                          setCaptchaToken(e.target.value);
                        }}
                        placeholder="Enter Captcha"
                      />
                    </Box>
                  </Flex>

                  <Button
                     fontSize="sm"
                     variant="brand"
                     fontWeight="500"
                     border={"1px solid black"}
                     bgColor={"black"}
                     borderRadius={"2em"}
                     w="100%"
                     h="50"
                     isLoading={loading}
                     loadingText="Please Wait"
                     mb="10px"
                      onClick={(e) => {
                        e.preventDefault();
                        forgot();
                      }}
                  >
                    Reset Password
                  </Button>
                </Stack>
                <Stack pt={0}>
                  <Text align={"center"}>
                    Remember Password?{" "}
                    <Link color={"blue.400"} to="/auth/index">
                      <u>
                        <b>Login</b>
                      </u>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Forgot;
