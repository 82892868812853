import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Select,
  FormLabel,
  FormControl,
  Input,
  Text,
  Checkbox,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { CreateRole, GetRole, UpdateRole } from "services/roleService";

export default function AddRole() {
  const location = useLocation();
  const navigate = useNavigate();
  const roleId = location.state?.roleId;

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const userPriviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];

  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const [loading, setLoading] = useState(false);
  const [priviledges, setPriviledges] = useState([]);
  const [role, setRole] = useState({
    roleName: "",
    roleAbbreviation: "",
    priviledges: ""
  });

  const handleAddRole = async () => {
    try {
      setLoading(true);
      if (!userPriviledges.includes("Admin")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      role.priviledges = priviledges;
      const response = roleId !== null && roleId !== undefined
        ? await UpdateRole(role, roleId, token)
        : await CreateRole(role, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/app/settings/roles");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRole = async () => {
    try {
      setLoading(true);
      const response = await GetRole(roleId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRole({
          roleName: response.data.roleName,
          roleAbbreviation: response.data.roleAbbreviation,
          priviledges: response.data.priviledges
        });
        const userPriviledges = response.data.priviledges !== null && response.data.priviledges !== "" ? response.data.priviledges : [];
        setPriviledges(userPriviledges);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const addPriviledge = async (priviledge) => {
    const updatedPriviledges = priviledges.concat(priviledge);
    setPriviledges(updatedPriviledges);
  };

  const removePriviledge = async (priviledge) => {
    const indexToRemove = priviledges.indexOf(priviledge);
    const updatedPriviledges = [...priviledges];
    if (indexToRemove !== -1) {
      updatedPriviledges.splice(indexToRemove, 1);
    }
    setPriviledges(updatedPriviledges);
  };

  const handleCheck = (priviledge, isChecked) => {
    if (isChecked) {
      addPriviledge(priviledge);
    } else {
      removePriviledge(priviledge);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allPriviledges = ['Admin', 'Member']
      setPriviledges(allPriviledges);
    } else {
      setPriviledges([]);
    }
  };

  useEffect(() => {
    if (roleId !== null && roleId !== undefined) {
      getRole();
    }
  }, []);


  return (
    <>
      <Box pt={{ base: "120px", md: "120px", xl: "120px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Roles
            </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/app/settings/roles");
              }}
            >
              Roles
            </Button>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="5"
          mb="3"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Name</FormLabel>
              <Input
                h="10"
                borderRadius={"2em"}
                border={"none"}
                outlineColor={"black"}
                maxLength={50}
                color={"black"}
                placeholder="Role Name"
                defaultValue={role.roleName}
                onChange={(e) => {
                  setRole({ ...role, roleName: e.target.value });
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Abbreviation</FormLabel>
              <Input
                h="10"
                borderRadius={"2em"}
                border={"none"}
                outlineColor={"black"}
                maxLength={50}
                color={"black"}
                placeholder="Role Abbreviation"
                defaultValue={role.roleAbbreviation}
                onChange={(e) => {
                  setRole({ ...role, roleAbbreviation: e.target.value });
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

       
        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="2"
          mt="2"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Priviledges</FormLabel>

              <Box width="100%">
                <Text fontWeight="bold">All</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Admin") !== -1}
                    onChange={(e) => {
                      handleCheck("Admin", e.target.checked);
                      handleSelectAll(e.target.checked);
                    }}
                  />
                  &nbsp;
                  <Text>All Priviledges</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Admin</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Admin") !== -1}
                    onChange={(e) =>
                      handleCheck("Admin", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Admin</Text>
                </Flex>
              </Box>

              <Box width="100%" mt="3">
                <Text fontWeight="bold">Member</Text>
                <Flex>
                  <Checkbox
                    isChecked={priviledges.indexOf("Member") !== -1}
                    onChange={(e) =>
                      handleCheck("Member", e.target.checked)
                    }
                  />
                  &nbsp;
                  <Text>Member</Text>
                </Flex>
              </Box>

            </FormControl>
          </Box>
        </SimpleGrid>

        <Box mt="3" width="100%" textAlign="right">
          <Button
           width={"10em"}
           border={"1px solid black"}
           color="#ffffff"
           _hover={{ color: "brand.500", background: "#000000" }}
           bgColor={"black"}
           borderRadius={"2em"}
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddRole}
          >
            {roleId !== null && roleId !== undefined ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
