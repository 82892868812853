import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  useToast
} from "@chakra-ui/react";
import AccountSetup from "./components/account";
import ContactSetup from "./components/contact";
import SkillsSetup from "./components/skills";
import AvailabilitySetup from "./components/availabilitySetup";
import SetupComplete from "./components/complete";
import { GetCountries, GetSkills } from "services/miscService";
import { UpdateUser, UpdateUserByID, GetUser, GetUserByID } from "services/userService";
import { GetRoles } from "services/roleService";

export default function ChangePassword() {
   const navigate = useNavigate();
   const location = useLocation();
   const userId = location.state?.userId;
 
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    country: "",
    state: "",
    skill: [],
    socialMedia: [],
    phone:"",
    lawEnforcement: "",
    website: "",
    lawEnforcementAffiliated: false,
    weeklyAvailability: "",
    hasEthicalPractices: "",
    role: ['Member']
  });

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState(null);
  const [skills, setSkills] = useState(null);
  const [roles, setRoles] = useState(null);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges") ? userState.priviledges : [];

  const toast = useToast();

  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries(token);
      setLoading(false);
      setCountries(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const getSkills = async () => {
    try {
      setLoading(true);
      const response = await GetSkills(token);
      setLoading(false);
      setSkills(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      let response;
      if(userId!==null && userId!==undefined){ 
        if(!priviledges.includes("Admin")){
          toast({
            title: "You are not allowed",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        response = await UpdateUserByID(user, userId, token);
      }else {
        response = await UpdateUser(user, token);
      }
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/app/dashboard");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const response = userId!==null && userId!==undefined? await GetUserByID(userId, token) : await GetUser(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCountries();
    getSkills();
    getUser();
    getRoles();
  }, []);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mb="50" minHeight="2000">
        {
          step === 1 ?
            <AccountSetup setStep={setStep} user={user} setUser={setUser} countries={countries} roles={roles} /> :
            step === 2 ?
              <ContactSetup setStep={setStep} user={user} setUser={setUser} /> :
              step === 3 ?
                <SkillsSetup setStep={setStep} user={user} setUser={setUser} allSkills={skills} /> :
                step === 4 ?
                  <AvailabilitySetup setStep={setStep} user={user} setUser={setUser} handleSubmit={handleUpdateProfile} /> :
                  step === 5 &&
                  <SetupComplete />
        }
      </Box>
    </>
  );
}

