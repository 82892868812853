import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo";

export default function AuthHeader() {
    return (
        <Box zIndex={100} style={{
            backgroundColor: "black",
            width: "100%",
            paddingBlock: "10px",
            paddingInline: "2em",
            height: "15vh",
            position: "sticky",
            top: 0,
            left: 0,
        }}>
            <Flex justifyContent={"end"} style={{
                width: "100%"
            }}>

                <Flex gap={"1em"}>
                    <Link to={"/auth/signup"}>
                        <Text color={"yellow"}>
                            SIGNUP
                        </Text>
                    </Link>
                    <Text fontWeight={"bold"} color={"white"}>
                        |
                    </Text>
                    <Link to={"/auth/index"}>
                        <Text color={"yellow"}>
                            LOGIN
                        </Text>
                    </Link>
                </Flex>
            </Flex>

            <Flex justifyContent={"space-between"}>
                <Logo />
                <Flex style={{ color: "white" }} alignItems={"center"} gap={"1em"}>
                    
                </Flex>
            </Flex>
        </Box>
    )
}
