import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  VStack,
  Text,
  Tag,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  GetUser
} from "services/userService";

export default function Roles() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const priviledges = userState.hasOwnProperty("priviledges")
    ? userState.priviledges
    : [];
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      setLoading(true);
      const response = await GetUser(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Box mt={{ base: "120px", md: "160px", xl: "160px" }} width="100%" mb="100">

        {user !== null &&
          <>
            <Box width="100%">
              <Box width="100%" textAlign={"right"}>
                <Link to={"/app/settings/setup"}>
                  <Button
                    colorScheme="teal"
                    background="black"
                    color="#ffffff"
                    _hover={{ color: "brand.500" }}
                    size="md"
                  >
                    Edit
                  </Button>
                </Link>
              </Box>
              <Box width="100%">
                <Text fontSize={"x-large"} fontWeight={"bold"}>
                  Profile | {user.firstName}  {user.lastName}
                </Text>
              </Box>

              <Stack direction={"row"} gap={"5em"} marginTop={"2em"}>

                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    First Name
                  </Text>
                  <Text>
                    {user.firstName}
                  </Text>
                </Flex>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Last Name
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user.lastName}
                  </Text>
                </Flex>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    E-mail
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user.email}
                  </Text>
                </Flex>
              </Stack>


              <Stack direction={"row"} gap={"5em"} marginTop={"1em"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    State/Providence
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user?.state}
                  </Text>
                </Flex>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Country
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user?.country}
                  </Text>
                </Flex>
              </Stack>
              <Stack direction={"row"} gap={"5em"} marginTop={"2em"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Weekly Hours Availability
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user?.weeklyAvailability}
                  </Text>
                </Flex>
              </Stack>
              <Stack direction={"row"} gap={"5em"} marginTop={"2em"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Work in Law Enforcement
                  </Text>
                  <Text fontFamily="ManropeRegular"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    {user?.lawEnforcementAffiliated ? "Yes" : "No"}
                  </Text>
                </Flex>
              </Stack>
              <Stack direction={"row"} gap={"5em"} marginTop={"2em"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Skill/Resources
                  </Text>
                  <Flex gap={"10px"} flexWrap={"wrap"}>
                    {
                      user?.skill.map((skill, idx) => (
                        <Tag key={idx} gap={"1em"} background="brand.500">
                          <Text fontFamily="ManropeRegular"
                            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                            {skill.skillName}
                          </Text>
                        </Tag>
                      ))
                    }
                  </Flex>
                </Flex>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} decoration={"underline"} fontFamily="ManropeSemiBold"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Social Media
                  </Text>
                  <VStack marginY={"1em"} alignItems={"flex-start"}>
                    {
                      user.socialMedia.length > 0 && user.socialMedia.map((socialMedia, idx) => (
                        <Tag key={idx} background="brand.500">
                          <Text fontFamily="ManropeRegular"
                            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>{socialMedia?.socialHandle}</Text>
                        </Tag>
                      ))
                    }
                  </VStack>
                </Flex>
              </Stack>
              <Flex flex={1} gap={"2em"} marginTop={"5em"} justifyContent={"flex-end"}>
                <Button
                  colorScheme="teal"
                  background="black"
                  color="#ffffff"
                  _hover={{ color: "brand.500" }}
                  size="md"
                >
                  Back
                </Button>
              </Flex>
            </Box>
          </>
        }

        <Box width="100%">
          <Text fontSize={"x-large"} fontWeight={"bold"}>
            Account Settings
          </Text>
          <VStack alignItems={"flex-start"} marginTop={"2em"}>
            <Link to={"/user/profile"}>
              <Text decoration={"underline"} fontFamily="ManropeRegular"
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                Profile
              </Text>
            </Link>
            <Link to={"/user/change-email"}>
              <Text decoration={"underline"} fontFamily="ManropeRegular"
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                Change Email Address
              </Text>
            </Link>
            <Link to={"/user/change-password"}>
              <Text decoration={"underline"} fontFamily="ManropeRegular"
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                Change Password
              </Text>
            </Link>
          </VStack>
        </Box>

      </Box>
    </>
  );
}
