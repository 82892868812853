// Chakra imports
import {
  Box,
  SimpleGrid,
  Container,
  Flex,
  VStack,
  Heading,
  Button,
  Link,
  Image,
  Center,
  Icon,
  Text,
} from "@chakra-ui/react";
import AuthHeader from "components/navigation/AuthHeader";
import PropTypes from "prop-types";
import React from "react";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Container
      width="100%"
      maxW="100%"
      padding="0px"
      h={{
        sm: "initial",
        md: "unset",
        lg: "100vh",
        xl: "97vh",
      }}
    >

      <AuthHeader />
      <SimpleGrid w="100%" columns={{ base: 1, sm: "1", md: 2, lg: 2 }}>
        <Box>
          <Box
            display={{ base: "none", md: "block" }}
            h="100%"
            minH="100vh"
            w="100%"
            left="0px"
            background="brand.500"
          >
            <Center width="100%" textAlign={"center"}>
              <Box boxSize="sm" width="100%">
                <VStack mt="40" width="100%">
                  <Text textAlign={"center"} color={"#000"} fontFamily={"ManropeSemiBold"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }} noOfLines={1}>
                    This is a resource for activists who could use assistance in building archives
                  </Text>
                  <Text textAlign={"center"} color={"#000"}  fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Feel free to contact anyone on this list for help! professional contact information may be present. Please assume the archivist is offering personal volunteer services and ask if you want their organisation's professional services.
                  </Text>
                </VStack>

                <Heading textAlign={"center"} marginBlock={"0.5em"} color={"#000"}>
                  Find an Archivist
                </Heading>

                <Link to="/auth/signup">
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  border={"1px solid black"}
                  bgColor={"black"}
                  borderRadius={"2em"}
                  w="150px"
                  h="50"
                  mb="10px"
                >
                  <b>Directory Search</b>
                </Button>
                </Link>

                <Link to="/auth/signup">
                  <Text fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }} textDecoration={"underline"} color={"#000"}>
                    Want to help? Signup to volunteer your archival skill and services.
                  </Text>
                </Link>

              </Box>
            </Center>

          </Box>
        </Box>

        <Box w="100%" zIndex={0}>
          <VStack>
            <Box width="100%" pt="5">
              {/*<NavLink to="/auth/index" pt="10">
                <Flex
                  align="center"
                  ps={{ base: "25px", lg: "0px" }}
                  pt={{ lg: "0px", xl: "0px" }}
                  w="fit-content"
                >
                  <Icon
                    as={FaChevronLeft}
                    me="12px"
                    h="13px"
                    w="8px"
                  />
                  <Text ms="0px" fontSize="sm">
                    Back to Home
                  </Text>
                </Flex>
              </NavLink>*/}
            </Box>
            <Box w="100%">{children}</Box>
          </VStack>
        </Box>
      </SimpleGrid>
    </Container>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
